import "./src/styles/tailwind.css";
import React from "react";
import LinksTargets from "./src/components/LinksTargets";

export const wrapPageElement = ({ element, props }) => {
  return (
    <div {...props}>
      {element}
      <LinksTargets />
    </div>
  );
};

// Function to append UTM parameters to links
function updateUTMParameters() {
  function isFromSpecificSource() {
    var referrer = document.referrer;
    console.log('Referrer:', referrer);
    return referrer.includes('instagram.com');
  }

  function isOnSpecificPage() {
    var currentPage = window.location.href;
    console.log('Current Page:', currentPage);
    return currentPage.includes('https://www.greatbritishdogs.co.uk/submit-story-2024-pp-form/');
  }

  function updateLinks() {
    var links = document.querySelectorAll('a');
    console.log('Total links:', links.length);
    for (var i = 0; i < links.length; i++) {
      var href = links[i].getAttribute('href');
      if (href && !href.includes('utm_source=specific_source')) {
        var separator = href.includes('?') ? '&' : '?';
        var utmParams = 'utm_source=specific_source&utm_medium=referrer';
        links[i].setAttribute('href', href + separator + utmParams);
        console.log('Updated link:', links[i].href);
      }
    }
  }

  function updatePageTitle() {
    var pageTitle = document.title;
    if (!pageTitle.endsWith(' | PP')) {
      document.title = pageTitle + ' | PP';
      console.log('Updated page title:', document.title);
    }
  }

  function updatePagePath() {
    var path = window.location.pathname;
    var search = window.location.search;
    if (!search.includes('utm_source=specific_source')) {
      var separator = search.includes('?') ? '&' : '?';
      var utmParams = 'utm_source=specific_source&utm_medium=referrer';
      var newPath = path + search + separator + utmParams;
      console.log('Updated page path:', newPath);
      history.replaceState({}, '', newPath);
    }
  }

  if (isFromSpecificSource() || isOnSpecificPage()) {
    updateLinks();
    updatePageTitle();
    updatePagePath();
  }
}

function onLinkClick(event) {
  if (event.target.tagName === 'A') {
    var linkHref = event.target.getAttribute('href');
    if (linkHref && !linkHref.includes('utm_source=specific_source')) {
      event.preventDefault();
      var separator = linkHref.includes('?') ? '&' : '?';
      var utmParams = 'utm_source=specific_source&utm_medium=referrer';
      var newHref = linkHref + separator + utmParams;
      console.log('Navigating to updated link:', newHref);
      window.location.href = newHref;
    }
  }
}

document.addEventListener('DOMContentLoaded', function() {
  updateUTMParameters();
  document.body.addEventListener('click', onLinkClick);
});

// Call the updateUTMParameters function when the route updates
export function onRouteUpdate() {
  updateUTMParameters();
}